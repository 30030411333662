<template>
  <v-footer
    id="home-footer"
    color="grey darken-4"
    dark
    min-height="72"
  >
    <v-container>
      <v-row>
        <v-col
          class="text-center grey--text"
          cols="12"
        >
          Una collaborazione SGD Group - Minnovi | P.IVA 04742950266 | <a
            href="/public/privacy-policy.html"
            target="_blank"
            class="grey--text"
            style="text-decoration: underline;"
          >
            Privacy Policy
          </a> | Copyright &copy; {{ currentYear }} Minnovi srl
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'HomeFooter',

    data: () => ({
      social: [
        'Facebook',
        'Twitter',
        'Instagram',
        'Linkedin',
      ],
    }),
    computed: {
      currentYear () {
        return new Date().getFullYear()
      },
    },
  }
</script>

<style lang="sass">
  #home-footer a
    text-decoration: none
</style>
